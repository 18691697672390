import { Add, CalendarToday } from "@mui/icons-material";
import {
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Box,
  Snackbar,
  SnackbarContent,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import BasicModal from "../components/modal";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import AddProject from "./AddProject/AddProject";
import axiosServices from "../utils/axiosServices";
import { useAuth } from "../context/AuthContext";
import Mermaid from "./Mermaid";
import { useConfirmationPopup } from "../components/openconfrmationmodel";
import Header from "../components/Header/Header";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarTodayIcon from "../assets/images/listViewCalendar.svg";
import Background from "../assets/images/Background.svg";
import moment from "moment-timezone";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import groupImg from "../assets/images/group.png";
import eclipseImg from "../assets/images/Ellipse.png";
import gridView from "../assets/images/gridView.png";
import ListView from "../assets/images/listView.png";
import UploadCodeBase from "../components/UploadCodeBase";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useGithub } from "../hooks/useGithub";
import GroupsIcon from "@mui/icons-material/Groups";
import AddCollaborator from "./AddCollaborator";

const projectStatusMap = {
  "Stage 1 Completed": "Ready",
  "Error: Took more than 7 hrs to run": "Error",
  "Summary being generated": "In Progress",
  "Awaiting codebase": "Awaiting codebase",
};

const projectStatusColorMap = {
  Ready: {
    backgroundColor: "#BBDEB2",
  },
  Error: {
    backgroundColor: "#F9C6C2",
  },
  "In Progress": {
    backgroundColor: "#F6DEA6",
  },
  "Awating codebase": {
    backgroundColor: "#E0E0E0",
  },
};

const getProjectStatus = (status) => {
  const processedStatus = projectStatusMap[status?.trim()] || status;
  if (
    processedStatus?.toLowerCase().includes("error") ||
    processedStatus?.toLowerCase().includes("no summaries generated")
  ) {
    return "Error";
  }
  return processedStatus;
};

function Loader() {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(0, 0, 0, 0.5)"
      zIndex="9999"
    >
      <CircularProgress />
    </Box>
  );
}

function Projects() {
  const { ConfirmationModal, openConfirmationPopup } = useConfirmationPopup();
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalContent, setModalContent] = useState();
  const [viewMode, setViewMode] = useState("grid");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("recent");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const {
    isGithubConnected,
    setIsGithubConnected,
    repositories,
    selectedRepo,
    setSelectedRepo,
    handleGithubConnect,
    handleGithubCodeExchange,
    fetchRepositories,
    validateGithubConnection,
  } = useGithub();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isGithubConnecting, setIsGithubConnecting] = useState(false);

  const formatDate = (utcTimestamp) => {
    const date = moment.utc(utcTimestamp);

    const istDate = date.tz("Asia/Kolkata");

    const formattedDate = istDate.format("Do MMM");
    const formattedTime = istDate.format("h:mm A");

    return { time: formattedTime, formattedDate };
  };

  const getAllProjects = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/projects?email=${user?.user?.email}`
      );

      if (response.data && response.data.length) {
        setProjects(response.data);
      } else {
        setProjects([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.user?.email]);

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      const projectId = sessionStorage.getItem("selectedProjectId");
      if (projectId) {
        setSelectedProjectId(projectId);
        setShowUploadModal(true);
      }

      setIsGithubConnecting(true);
      handleGithubCodeExchange(code).then((success) => {
        if (success) {
          setIsGithubConnected(true);
          fetchRepositories();
        }
        setIsGithubConnecting(false);
      });
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [handleGithubCodeExchange, setIsGithubConnected, fetchRepositories]);

  useEffect(() => {
    const handleShowSnackbar = (event) => {
      setSnackbarMessage(event.detail.message);
      setSnackbarOpen(true);
    };

    window.addEventListener("showSnackbar", handleShowSnackbar);

    return () => {
      window.removeEventListener("showSnackbar", handleShowSnackbar);
    };
  }, []);

  const openTheModal = (modalPurpose, projectId = null) => {
    if (modalPurpose === "uploadCodebase") {
      sessionStorage.setItem("selectedProjectId", projectId);
      setSelectedProjectId(projectId);
      setShowUploadModal(true);
      return;
    }

    if (modalPurpose === "addProject") {
      console.log("Opening AddProject modal");
      setModalContent(
        <AddProject
          onSuccess={(projectData) => {
            console.log("AddProject success called");
            handleAddProjectSuccess();
            if (projectData?.project_id) {
              openTheModal("uploadCodebase", projectData.project_id);
            }
          }}
          onFailure={() => {
            console.log("AddProject failure called");
            handleAddProjectFailure();
          }}
          setIsModalOpen={setIsModalOpen}
          getAllProjects={getAllProjects}
        />
      );
      setIsModalOpen(true);
    } else if (modalPurpose === "mermaid_diagram") {
      setModalContent(<Mermaid projectId={projectId} />);
      setIsModalOpen(true);
    }
  };

  const handleAddProjectSuccess = () => {
    console.log("handleAddProjectSuccess called");
    setIsModalOpen(false);
    setShowUploadModal(false);
    setSnackbarMessage("Project created successfully");
    setSnackbarOpen(true);
    getAllProjects();
  };

  const handleAddProjectFailure = () => {
    console.log("handleAddProjectFailure called");
    setIsModalOpen(false);
    setShowUploadModal(false);
    setSnackbarMessage("Project creation failed");
    setSnackbarOpen(true);
    getAllProjects();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleModalClose = () => {
    console.log("handleModalClose called");
    setIsModalOpen(false);
    setShowUploadModal(false);
    setModalContent(null);
    sessionStorage.removeItem("selectedProjectId");
  };

  const handleChat = (project) => {
    if (getProjectStatus(project.status) === "Error") {
      return;
    }
    navigate("/chat", {
      state: {
        project,
        projectName: project.project_name,
        projectId: project.project_id,
      },
    });
  };

  const handleIconClick = (projectId) => {
    openConfirmationPopup({
      confirmButtonLabel: "Delete",
      cancelButtonLabel: "Cancel",
      title: "Are you sure?",
      message: "Do you really want to delete this project?",
      onConfirm: () => handleDelete(projectId),
    });
  };

  const handleDelete = async (projectId) => {
    const project = projects.find((proj) => proj.project_id === projectId);
    const projectName = project ? project.project_name : "Project";

    try {
      setIsLoading(true);
      const response = await axiosServices.post(`/projects/delete`, {
        projectId,
        email: user?.user?.email,
      });

      if (response.status === 200) {
        setSnackbarMessage(`Project "${projectName}" has been deleted.`);
        setSnackbarOpen(true);

        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.project_id !== projectId)
        );
      } else {
        throw new Error("Failed to delete the project.");
      }
    } catch (error) {
      console.error("Delete failed:", error);
      setSnackbarMessage("Failed to delete the project.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCollaboratorClick = (project) => {
    setModalContent(
      <AddCollaborator
        project={project}
        onClose={() => setIsModalOpen(false)}
      />
    );
    setIsModalOpen(true);
  };

  const filteredProjects = projects
    .filter((project) =>
      project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === "recent") {
        return new Date(b.created_at) - new Date(a.created_at);
      }
      return 0;
    });

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const sortedProjects = [...filteredProjects].sort((a, b) => {
    if (sortOrder === "oldest") {
      return new Date(a.created_at) - new Date(b.created_at);
    } else {
      return new Date(b.created_at) - new Date(a.created_at);
    }
  });

  const handleFileSelected = (file) => {
    console.log("File selected:", file);
  };

  const handleFileDelete = () => {
    console.log("File deleted");
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: viewMode === "grid" ? `url(${Background})` : "none",
          backgroundSize: "contain",
          backgroundRepeat: "repeat",
          minHeight: "100vh",
          padding: 0,
          m: 0,
          position: "relative",
          "@media (max-width: 600px)": {
            padding: "0 10px",
          },
        }}
      >
        {isLoading && <Loader />}
        <Header />

        <Container maxWidth={false} disableGutters sx={{ m: 0, p: 3, py: 1 }}>
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                bgcolor: "rgba(255, 255, 255, 0.7)",
                zIndex: 999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}

          <Box
            sx={{
              display: isLoading ? "none" : "block",
            }}
          >
            {projects.length === 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="90vh"
                textAlign="center"
              >
                <Box
                  sx={{
                    maxWidth: 400,
                    width: "100%",
                    backgroundImage: `url(${eclipseImg})`,
                    backgroundRepeat: "no-repeat",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      src={groupImg}
                      alt="group"
                      style={{
                        marginRight: "50px",
                        width: "180px",
                        marginBottom: "20px",
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                    p: 4,
                    borderRadius: 2,
                    bottom: "100px",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "text.primary",
                      fontWeight: "bold",
                      mr: 6,
                    }}
                  >
                    Oops, it's pretty lonely here!
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{
                      color: "#525252",
                      mt: 1,
                      mr: 7,
                    }}
                  >
                    Looks like your project list is as empty as a Monday
                    morning.
                    <br />
                    How about starting something awesome?
                  </Typography>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => openTheModal("addProject")}
                    sx={{
                      mt: 5,
                      mr: 5,
                      fontSize: "0.8rem",
                      textTransform: "none",
                      borderRadius: "10px",
                      width: "40%",
                      maxWidth: "190px",
                    }}
                  >
                    Upload Codebase
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mb={8}
                  justifyContent="space-between"
                  sx={{ padding: "4px 0" }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#525252",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    My Projects
                  </Typography>

                  <Box display="flex" alignItems="center" gap={1} mr={2}>
                    <TextField
                      variant="outlined"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      sx={{
                        width: "160px",
                        marginRight: 1,
                        margin: "0px",
                        padding: "0px",
                        backgroundColor: "white",
                      }}
                      InputProps={{
                        style: {
                          height: "45px",
                          fontSize: "0.8rem",
                          padding: "0px 8px",
                        },
                      }}
                    />
                    <Select
                      value={sortOrder}
                      onChange={handleSortChange}
                      displayEmpty
                      sx={{
                        width: "120px",
                        marginRight: 1,
                        margin: "0px",
                        padding: "0px",
                        height: "45px",
                        fontSize: "0.8rem",
                        lineHeight: "1.2",
                        backgroundColor: "white",
                      }}
                      inputProps={{
                        style: {
                          padding: "0px 8px",
                        },
                      }}
                    >
                      <MenuItem
                        value="recent"
                        sx={{ fontSize: "0.8rem", height: "30px" }}
                      >
                        Sort by Recent
                      </MenuItem>
                      <MenuItem
                        value="oldest"
                        sx={{ fontSize: "0.8rem", height: "30px" }}
                      >
                        Sort by Oldest
                      </MenuItem>
                    </Select>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      sx={{
                        borderRadius: "5px",
                        textTransform: "none",
                        padding: "9px 8px",
                        marginRight: "20px",
                      }}
                      startIcon={<Add />}
                      onClick={() => openTheModal("addProject")}
                    >
                      Upload Codebase
                    </Button>
                    <Box
                      component="img"
                      src={gridView}
                      alt="Grid View"
                      className="grid-view-icon"
                      onClick={() => setViewMode("grid")}
                      sx={{
                        boxShadow:
                          viewMode === "grid"
                            ? "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1)"
                            : "",
                        cursor: "pointer",
                        backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    <Box
                      component="img"
                      src={ListView}
                      alt="List View"
                      className="list-view-icon"
                      onClick={() => setViewMode("list")}
                      sx={{
                        boxShadow:
                          viewMode === "list"
                            ? "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1)"
                            : "",
                        cursor: "pointer",
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                </Box>

                {viewMode === "grid" ? (
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
                    gap={2}
                    sx={{
                      padding: 0,
                      "@media (max-width: 600px)": {
                        gridTemplateColumns: "1fr",
                      },
                    }}
                  >
                    {sortedProjects.map((project) => {
                      const projectStatus = getProjectStatus(project.status);
                      const isDisabled = projectStatus !== "Ready";
                      const isOwner = project.role?.toLowerCase() === "owner";
                      const showUploadButton =
                        projectStatus === "Awating codebase";
                      return (
                        <Card
                          key={project.project_id}
                          sx={{
                            maxWidth: 330,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            padding: 2,
                            pb: 0.3,
                            borderRadius: "10px",
                            position: "relative",
                            cursor: !isDisabled ? "pointer" : "default",
                          }}
                          onClick={() => !isDisabled && handleChat(project)}
                        >
                          {isOwner && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: 4,
                                right: 8,
                                display: "flex",
                              }}
                            >
                              <Tooltip title="View Collaborators">
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCollaboratorClick({
                                      project_id: project.project_id,
                                      project_name: project.project_name,
                                    });
                                  }}
                                  sx={{
                                    color: "#6C757D",
                                    "&:hover": { color: "#2196F3" },
                                    opacity:
                                      projectStatus === "Error" ? 0.5 : 1,
                                    pointerEvents:
                                      projectStatus === "Error"
                                        ? "none"
                                        : "auto",
                                  }}
                                >
                                  <GroupsIcon sx={{ fontSize: "20px" }} />
                                </IconButton>
                              </Tooltip>
                              {projectStatus === "In Progress" || !isOwner ? (
                                ""
                              ) : (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleIconClick(project.project_id);
                                  }}
                                  sx={{ color: "#B8B8B8", fontSize: "25px" }}
                                >
                                  <DeleteOutlineOutlinedIcon
                                    sx={{ fontSize: "16px" }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          )}

                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 8,
                              display: "flex",
                            }}
                          ></Box>
                          <Box
                            display="flex"
                            sx={{
                              marginBottom: 1,
                              mr: 2,
                            }}
                          >
                            <Chip
                              label={projectStatus}
                              variant="outlined"
                              size="small"
                              sx={{
                                fontSize: "0.7rem",
                                backgroundColor:
                                  projectStatusColorMap[projectStatus]
                                    ?.backgroundColor,
                                border: "none",
                                p: 0.5,
                              }}
                            />
                          </Box>

                          <CardContent sx={{ flex: 1, paddingTop: 1, pl: 1 }}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "1rem",
                                color: "#525252",
                                marginBottom: "8px",
                              }}
                            >
                              {project.project_name}
                            </Typography>
                            {projectStatus === "Error" && (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#DD6A67",
                                  fontSize: "0.8rem",
                                }}
                              >
                                Something went wrong with this project. Please
                                delete it and try again.
                              </Typography>
                            )}
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#525252",
                                fontSize: "0.9rem",
                              }}
                            >
                              {project.description}
                            </Typography>
                          </CardContent>

                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                              paddingTop: 1,
                              paddingBottom: 1,
                              borderTop: "0.5px solid #CBD5E1",
                              marginTop: "auto",
                              width: "100%",
                            }}
                          >
                            {showUploadButton && (
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openTheModal(
                                    "uploadCodebase",
                                    project.project_id
                                  );
                                }}
                                startIcon={<FileUploadOutlinedIcon />}
                                sx={{
                                  mr: "auto",
                                  textTransform: "none",
                                  fontSize: "0.7rem",
                                  py: 0.5,
                                }}
                              >
                                Upload Codebase
                              </Button>
                            )}
                            <Box display="flex" alignItems="center">
                              <AccessTimeOutlinedIcon
                                sx={{
                                  color: "#525252",
                                  mr: 0.5,
                                  fontSize: "0.9rem",
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#525252",
                                  fontSize: "0.7rem",
                                }}
                              >
                                {formatDate(project.created_at).time}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{ ml: 2 }}
                            >
                              <CalendarToday
                                sx={{
                                  color: "#525252",
                                  mr: 0.5,
                                  fontSize: "0.8rem",
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#525252",
                                  fontSize: "0.7rem",
                                }}
                              >
                                {formatDate(project.created_at).formattedDate}
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        maxWidth: "1300px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 1,
                        cursor: "pointer",
                      }}
                    >
                      {sortedProjects.length === 0 ? (
                        <Typography variant="h6" sx={{ color: "#525252" }}>
                          No matches found
                        </Typography>
                      ) : (
                        sortedProjects.map((project, index) => {
                          const projectStatus = getProjectStatus(
                            project.status
                          );
                          const isDisabled = projectStatus !== "Ready";
                          const isOwner =
                            project.role?.toLowerCase() === "owner";
                          const showUploadButton =
                            projectStatus === "Awating codebase";
                          return (
                            <Box
                              key={project.project_id}
                              display="flex"
                              alignItems="center"
                              onClick={() => !isDisabled && handleChat(project)}
                              sx={{
                                borderBottom:
                                  index === sortedProjects.length - 1
                                    ? "none"
                                    : "1px solid #e0e0e0",
                                marginBottom: "5px",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{ flex: 1 }}
                              >
                                <Typography variant="body1" fontSize={"0.9rem"}>
                                  {project.project_name}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{ flex: 1 }}
                              >
                                <Chip
                                  label={projectStatus}
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    fontSize: "0.7rem",
                                    backgroundColor:
                                      projectStatusColorMap[projectStatus]
                                        ?.backgroundColor,
                                    border: "none",
                                  }}
                                />
                                {projectStatus === "Error" && (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#DD6A67",
                                      fontSize: "0.6rem",
                                      ml: 1,
                                    }}
                                  >
                                    Something went wrong with this project.
                                    Please delete it and try again.
                                  </Typography>
                                )}
                                <Box sx={{ ml: 2 }}>
                                  {showUploadButton && (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openTheModal(
                                          "uploadCodebase",
                                          project.project_id
                                        );
                                      }}
                                      startIcon={<FileUploadOutlinedIcon />}
                                      sx={{
                                        textTransform: "none",
                                        fontSize: "0.7rem",
                                        py: 0.5,
                                        mr: 2,
                                      }}
                                    >
                                      Upload Codebase
                                    </Button>
                                  )}
                                </Box>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                sx={{ flex: 1 }}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flex={2}
                                >
                                  <AccessTimeOutlinedIcon
                                    sx={{
                                      color: "#525252",
                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    sx={{ marginLeft: 1, fontSize: "0.8rem" }}
                                  >
                                    {formatDate(project.created_at).time}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flex={2}
                                >
                                  <Box
                                    component="img"
                                    src={CalendarTodayIcon}
                                    alt="Calendar Icon"
                                    sx={{ width: "16px", height: "16px" }}
                                  />
                                  <Typography
                                    variant="body2"
                                    sx={{ marginLeft: 1, fontSize: "0.8rem" }}
                                  >
                                    {
                                      formatDate(project.created_at)
                                        .formattedDate
                                    }
                                  </Typography>
                                </Box>
                                {isOwner && (
                                  <Tooltip title="View Collaborators">
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCollaboratorClick({
                                          project_id: project.project_id,
                                          project_name: project.project_name,
                                        });
                                      }}
                                      sx={{
                                        color: "#6C757D",
                                        "&:hover": { color: "#2196F3" },
                                        opacity:
                                          projectStatus === "Error" ? 0.5 : 1,
                                        pointerEvents:
                                          projectStatus === "Error"
                                            ? "none"
                                            : "auto",
                                      }}
                                    >
                                      <GroupsIcon sx={{ fontSize: "20px" }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{ mx: 1 }}
                                  flex={3}
                                >
                                  {projectStatus !== "In Progress" &&
                                    isOwner && (
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleIconClick(project.project_id);
                                        }}
                                        sx={{ color: "#B8B8B8" }}
                                      >
                                        <DeleteOutlineOutlinedIcon
                                          sx={{ fontSize: "16px" }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                              <Box></Box>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>

          <BasicModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            content={modalContent}
          />
          {ConfirmationModal}

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
          >
            <SnackbarContent
              style={{
                backgroundColor: "teal",
              }}
              message={<span id="message-snackbar">{snackbarMessage}</span>}
            />
          </Snackbar>

          <UploadCodeBase
            showDragAndDrop={showUploadModal}
            handleLater={() => setShowUploadModal(false)}
            setIsModalOpen={setShowUploadModal}
            isGithubConnected={isGithubConnected}
            isGithubConnecting={isGithubConnecting}
            handleGithubConnect={handleGithubConnect}
            validateGithubConnection={validateGithubConnection}
            repositories={repositories}
            selectedRepo={selectedRepo}
            setSelectedRepo={setSelectedRepo}
            selectedProjectId={selectedProjectId}
            getAllProjects={getAllProjects}
            setShowUploadModal={setShowUploadModal}
            handleFileSelected={handleFileSelected}
            handleFileDelete={handleFileDelete}
          />
        </Container>
      </Box>
    </>
  );
}

export default Projects;
