import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  LinearProgress,
  Autocomplete,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import DragAndDrop from "./DragAndDrop/DragDropBox";
import Loader from "./loader";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AzureDevOpsIcon from "@mui/icons-material/Cloud";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import axiosServices from "../utils/axiosServices";

function UploadCodeBase({
  showDragAndDrop,
  handleLater,
  setIsModalOpen,
  isGithubConnected,
  isGithubConnecting,
  handleGithubConnect,
  validateGithubConnection,
  repositories,
  selectedRepo,
  setSelectedRepo,
  handleFileSelected,
  handleFileDelete,
  selectedProjectId,
  getAllProjects,
  setShowUploadModal,
  uploadedFileName,
  setUploadedFileName,
  onClose,
}) {
  const [bufferedFile, setBufferedFile] = useState(null);
  const [draggedFile, setDraggedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [publicRepoUrl, setPublicRepoUrl] = useState("");
  const [publicRepoBranch, setPublicRepoBranch] = useState("");
  const [privateRepoBranch, setPrivateRepoBranch] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const publicUrlInputRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("github");
  const [uploadError, setUploadError] = useState("");

  useEffect(() => {
    if (showDragAndDrop) {
      validateGithubConnection();
    } else {
      setBufferedFile(null);
      setDraggedFile(null);
      setSelectedFileName(null);
      setSelectedRepo(null);
      setUploadedFileName?.("");
      setPublicRepoUrl("");
      setPublicRepoBranch("");
      setPrivateRepoBranch("");
      setUploadError("");
      handleFileDelete?.();
    }
  }, [
    showDragAndDrop,
    setUploadedFileName,
    handleFileDelete,
    setSelectedRepo,
    validateGithubConnection,
  ]);

  console.info({ repositories });

  const handleTabSelect = (value) => {
    setSelectedTab(value);
    setBufferedFile(null);
    setDraggedFile(null);
    setSelectedFileName(null);
    setSelectedRepo(null);
    setUploadedFileName?.("");
    setPublicRepoUrl("");
    setPublicRepoBranch("");
    setPrivateRepoBranch("");
    handleFileDelete?.();
  };

  const handleRepoSelection = (event, newValue) => {
    if (!newValue) {
      setBufferedFile(null);
      setSelectedRepo(null);
      setUploadedFileName?.("");
      setPrivateRepoBranch("");
      handleFileDelete?.();
      return;
    }

    // Clear other upload methods
    setPublicRepoUrl("");
    setPublicRepoBranch("");
    setDraggedFile(null);
    setSelectedFileName(null);

    const file = {
      name: newValue.name,
      content: newValue,
    };
    setBufferedFile(file);
    setSelectedRepo(newValue.html_url);
    setUploadedFileName?.(newValue.name);
    setPrivateRepoBranch(newValue.default_branch || "main");
    handleFileSelected?.(file);
  };

  const handleDragAndDrop = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      setDraggedFile(file);
      setSelectedFileName(file.name);
      setPublicRepoUrl("");
      setPublicRepoBranch("");
      setPrivateRepoBranch("");
      setBufferedFile(null);
      setSelectedRepo(null);
      setUploadedFileName?.(file.name);
      handleFileSelected?.(file);
    }
  };

  const handleFileRemove = () => {
    setDraggedFile(null);
    setSelectedFileName(null);
    setUploadedFileName?.("");
    handleFileDelete?.();
  };

  const handleUploadSuccess = async (response) => {
    console.log("Upload success:", response);
    if (onClose) {
      onClose();
    }
    setIsModalOpen(false);
    setShowUploadModal(false);
    getAllProjects();
    handleFileDelete();
    setDraggedFile(null);
    setUploadError("");
  };

  const handleUploadError = (error) => {
    console.error("Upload error:", error);
    if (
      error.response?.data?.error === "Failed to download repository" &&
      error.response?.data?.details === "Repository or branch not found"
    ) {
      setUploadError("Repository or branch not found");
    }
  };

  const onSubmit = async (event) => {
    event?.preventDefault();

    try {
      setIsLoading(true);
      setUploadError("");

      if (!selectedProjectId) {
        console.error("Project ID is missing");
        return;
      }
      if (!draggedFile && !bufferedFile && !publicRepoUrl) {
        console.error("No file or repository URL provided");
        return;
      }

      setIsUploading(true);
      const formData = new FormData();
      formData.append("project_id", selectedProjectId);

      if (bufferedFile) {
        formData.append("repositoryUrl", bufferedFile.content.html_url);
        formData.append(
          "isPrivateRepository",
          bufferedFile.content.private ? "true" : "false"
        );
        formData.append(
          "branch",
          privateRepoBranch.trim() ||
            bufferedFile.content.default_branch ||
            "main"
        );
        console.log(
          "Sending selected repository URL:",
          bufferedFile.content.html_url,
          "Is Private:",
          bufferedFile.content.private,
          "Branch:",
          privateRepoBranch.trim() ||
            bufferedFile.content.default_branch ||
            "main"
        );
      } else if (publicRepoUrl) {
        let downloadUrl = publicRepoUrl.trim();
        if (downloadUrl.includes("github.com")) {
          downloadUrl = downloadUrl.replace(/\/$/, "");
          downloadUrl = downloadUrl.replace(/\.git$/, "");
          const branch = publicRepoBranch.trim() || "main";
          formData.append("repositoryUrl", downloadUrl);
          formData.append("isPrivateRepository", "false");
          formData.append("branch", branch);
        }
      } else if (draggedFile) {
        formData.append("file", draggedFile);
        console.log("Sending dragged file:", draggedFile.name);
      }

      const response = await axiosServices.post(
        "/projects/uploadcodebase",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      handleUploadSuccess(response);
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
      setIsLoading(false);
    }
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setPublicRepoUrl(text);
      setPublicRepoBranch("main");
      setBufferedFile(null);
      setDraggedFile(null);
      setSelectedFileName(null);
      setUploadedFileName?.("");
      setSelectedRepo(null);
      handleFileDelete?.();
    } catch (error) {
      console.error("Failed to read clipboard:", error);
    }
  };

  return (
    <Modal
      open={showDragAndDrop}
      onClose={() => handleLater(() => setIsModalOpen(false))}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          bgcolor: "white",
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          width: "700px",
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading && <Loader open={isLoading} />}

        <IconButton
          onClick={() => handleLater(() => setIsModalOpen(false))}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
          Upload Your Codebase
        </Typography>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mb: 3,
            backgroundColor: "#f8f9fa",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Box
              onClick={() => handleTabSelect("github")}
              sx={{
                flex: 1,
                p: 1.5,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                position: "relative",
                borderBottom: selectedTab === "github" ? 2 : 0,
                borderColor: "primary.main",
                color:
                  selectedTab === "github" ? "primary.main" : "text.secondary",
                backgroundColor:
                  selectedTab === "github" ? "#fff" : "transparent",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor:
                    selectedTab === "github" ? "#fff" : "rgba(0, 0, 0, 0.04)",
                  color: "primary.main",
                },
                fontWeight: selectedTab === "github" ? 500 : 400,
              }}
            >
              <GitHubIcon sx={{ fontSize: 18 }} />
              <Typography sx={{ fontSize: "0.875rem" }}>GitHub</Typography>
            </Box>

            <Box
              onClick={() => handleTabSelect("manual")}
              sx={{
                flex: 1,
                p: 1.5,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                position: "relative",
                borderBottom: selectedTab === "manual" ? 2 : 0,
                borderColor: "primary.main",
                color:
                  selectedTab === "manual" ? "primary.main" : "text.secondary",
                backgroundColor:
                  selectedTab === "manual" ? "#fff" : "transparent",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor:
                    selectedTab === "manual" ? "#fff" : "rgba(0, 0, 0, 0.04)",
                  color: "primary.main",
                },
                fontWeight: selectedTab === "manual" ? 500 : 400,
              }}
            >
              <UploadFileIcon sx={{ fontSize: 18 }} />
              <Typography sx={{ fontSize: "0.875rem" }}>
                Manual Upload
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          {selectedTab === "github" && (
            <Box>
              {!isGithubConnected ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleGithubConnect}
                  disabled={isGithubConnecting}
                  fullWidth
                  startIcon={<GitHubIcon />}
                  sx={{
                    mb: 2,
                    py: 1.5,
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 500,
                  }}
                >
                  {isGithubConnecting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Connect to GitHub"
                  )}
                </Button>
              ) : (
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      color: "success.main",
                      fontWeight: 500,
                    }}
                  >
                    <GitHubIcon sx={{ mr: 1, fontSize: 20, color: "black" }} />
                    You're all set! Connected to GitHub
                  </Typography>

                  {/* Private Repository Section */}
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 2, fontWeight: 500 }}
                  >
                    Select Private Repository
                  </Typography>
                  <Autocomplete
                    fullWidth
                    options={repositories}
                    getOptionLabel={(repo) => repo.full_name}
                    value={
                      repositories.find(
                        (repo) => repo.html_url === selectedRepo
                      ) || null
                    }
                    onChange={handleRepoSelection}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search repositories..."
                      />
                    )}
                    sx={{ mb: 2 }}
                  />
                  {bufferedFile && (
                    <TextField
                      fullWidth
                      required
                      label="Branch"
                      placeholder={`Branch (default: ${bufferedFile.content.default_branch || "main"})`}
                      value={privateRepoBranch}
                      onChange={(e) => setPrivateRepoBranch(e.target.value)}
                      disabled={isLoading}
                      variant="outlined"
                      sx={{ mt: 1 }}
                      error={!privateRepoBranch.trim()}
                      helperText={
                        !privateRepoBranch.trim()
                          ? "Branch name is required"
                          : ""
                      }
                    />
                  )}

                  <Divider sx={{ my: 3 }} />

                  {/* Public Repository Section */}
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 2, fontWeight: 500 }}
                  >
                    Or Enter Public Repository URL
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                    <TextField
                      fullWidth
                      placeholder="Enter public repository URL"
                      value={publicRepoUrl}
                      onChange={(e) => {
                        setPublicRepoUrl(e.target.value);
                        setUploadError("");
                      }}
                      inputRef={publicUrlInputRef}
                      error={!!uploadError}
                      helperText={uploadError}
                    />
                    <Tooltip title="Paste URL">
                      <IconButton onClick={handlePaste} size="small">
                        <ContentPasteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {publicRepoUrl && (
                    <TextField
                      fullWidth
                      required
                      label="Branch"
                      placeholder="Enter branch name"
                      value={publicRepoBranch}
                      onChange={(e) => setPublicRepoBranch(e.target.value)}
                      disabled={isLoading}
                      variant="outlined"
                      sx={{ mt: 1 }}
                      error={!publicRepoBranch.trim()}
                      helperText={
                        !publicRepoBranch.trim()
                          ? "Branch name is required"
                          : ""
                      }
                    />
                  )}
                </Box>
              )}
            </Box>
          )}

          {selectedTab === "azure" && (
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              startIcon={<AzureDevOpsIcon />}
              sx={{
                py: 1.5,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              Connect to Azure DevOps
            </Button>
          )}

          {selectedTab === "manual" && (
            <Box sx={{ mb: 4 }}>
              <Alert severity="warning" sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  ⚠️ IMPORTANT: Before uploading your codebase, please remove:
                </Typography>
                <ul
                  style={{
                    marginTop: "4px",
                    marginBottom: "4px",
                    paddingLeft: "20px",
                  }}
                >
                  <li>Executable files (.exe, .dll, etc.)</li>
                  <li>
                    Build artifacts and intermediate files (build/, dist/,
                    node_modules/, etc.)
                  </li>
                  <li>Binary and cache files</li>
                  <li>
                    Any other large files that aren't essential source code
                  </li>
                </ul>
                <Typography variant="body2">
                  This will ensure faster processing and better results from the
                  engine.
                </Typography>
              </Alert>
              {selectedFileName ? (
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                    bgcolor: "#f5f5f5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Selected File:
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{ fontWeight: 500 }}
                    >
                      {selectedFileName}
                    </Typography>
                    {draggedFile && (
                      <Typography variant="caption" color="text.secondary">
                        Size: {(draggedFile.size / 1024).toFixed(2)} KB
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={handleFileRemove}
                  >
                    Remove
                  </Button>
                </Box>
              ) : (
                <DragAndDrop
                  onFileSelected={handleDragAndDrop}
                  uploadedFileName={uploadedFileName}
                  required
                  id="fileInput"
                  disabled={isLoading}
                />
              )}
            </Box>
          )}

          {selectedTab && (
            <Box display="flex" justifyContent="flex-end" mt={3} gap={1}>
              <Button
                variant="outlined"
                onClick={() => handleLater(() => setIsModalOpen(false))}
                disabled={isUploading}
                sx={{ textTransform: "none" }}
              >
                Skip for now
              </Button>
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={
                  (!draggedFile && !selectedRepo && !publicRepoUrl) ||
                  isUploading ||
                  (selectedRepo && !privateRepoBranch.trim()) ||
                  (publicRepoUrl && !publicRepoBranch.trim())
                }
                sx={{ textTransform: "none" }}
              >
                {isUploading ? `Uploading ${uploadProgress}%` : "Submit"}
              </Button>
            </Box>
          )}

          {isUploading && (
            <Box sx={{ width: "100%", mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 4,
                  },
                }}
              />
              <Typography
                variant="caption"
                display="block"
                textAlign="center"
                mt={1}
              >
                Uploading: {uploadProgress}%
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default UploadCodeBase;
